const colorList = [
    'rgb(230, 25, 75)', 
    'rgb(60, 180, 75)', 
    'rgb(201, 225, 25)', 
    'rgb(0, 130, 200)', 
    'rgb(245, 130, 48)', 
    'rgb(145, 30, 180)', 
    'rgb(70, 240, 240)', 
    'rgb(240, 50, 230)', 
    'rgb(210, 245, 60)', 
    'rgb(201, 190, 212)', 
    'rgb(0, 128, 128)', 
    'rgb(220, 190, 255)', 
    'rgb(170, 110, 40)', 
    'rgb(201, 250, 200)', 
    'rgb(128, 0, 0)', 
    'rgb(170, 255, 195)', 
    'rgb(128, 128, 0)', 
    'rgb(201, 215, 180)', 
    'rgb(0, 0, 128)', 
    'rgb(128, 128, 128)'
]

export default colorList;